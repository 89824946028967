<template>
    <div class="eki-score">
        <div class="score-card">
            <div class="rate-container" v-if="playerX.currentSong || true">
                <h1 class="song-title">{{ playerX.currentSong?.title ?? $t('epicSong') }}</h1>
                <h2 class="song-art">{{ playerX.currentSong?.art.name ?? $t('amazingArtists') }}</h2>
                <div class="rate-widget">{{ rate }}</div>
            </div>
            <div class="next-song-container">
                <va-progress-circle v-model="nextSongIn" :thickness="0.2" size="80px" color="warning" />
                <span class="next-indicator" v-if="playerX.queue[0]">{{ $t('song.nextIn', { time: nextInSeconds }) }}</span>
                <div class="next-song-info" v-if="playerX.queue[0]">
                    <div class="thumbnail">
                        <va-image class="bg-img" :src="getImage(playerX.queue[0]?.gen.img)" lazy />
                        <div class="play-container" @click="playNext()">
                            <div class="play-icon" :title="$t('song.next')">
                                <fa-icon icon="fa-solid fa-play"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="song-info">
                        <div class="title-span">
                            {{ playerX.queue[0]?.title }}
                        </div>
                        <div class="artist-span">
                            {{ playerX.queue[0]?.art.name }}
                        </div>
                        <div class="genre-span">
                            {{ playerX.queue[0]?.gen.name }}
                        </div>
                    </div>
                </div>
            </div>
            <fa-icon class="close-icon" icon="fa-solid fa-close" :title="$t('song.next')" @click="playNext()"/>
        </div>
    </div>
</template>

<script setup>
import { usePlayerStore } from "@/stores/player";
import { computed, onMounted, ref } from "vue";
import { useUtilsStore } from "@/stores/utils";

const playerX = usePlayerStore();
const utilsX = useUtilsStore();
const nextSongIn = ref(0);
const rate = ref(99);
const animateInterval = ref(null);
const countDownInterval = ref(null);
const currentImage = ref(null);
const nextInSeconds = computed(() => {
    return Math.floor((140 - nextSongIn.value) * 7 / 100)
});

function playNext() {
    clearInterval(countDownInterval.value);
    clearInterval(animateInterval.value);
    if (playerX.queue[0]) {
        playerX.skip('next');
    }else if(playerX.currentSong){
        playerX.stop();
    }
    utilsX.showScore = false;
}

onMounted(() => {
    playerX.findAndPlayEffect("Conteo");

    animateInterval.value = setInterval(() => {
        rate.value = Math.floor(Math.random() * 99)
    }, 100);

    let played = false;

    countDownInterval.value = setInterval(() => {
        if (nextSongIn.value >= 140) {
            clearInterval(countDownInterval.value);
            playNext();
        } else if (nextSongIn.value >= 50 && !played) {
            clearInterval(animateInterval.value);
            rate.value = playerX.songRate ?? 100;

            if (playerX.songRate === 100) {
                playerX.findAndPlayEffect("Cien");
            }
            else if (playerX.songRate > 90) {
                playerX.findAndPlayEffect("Bien");
            }
            else if (playerX.songRate > 80) {
                playerX.findAndPlayEffect("Normal");
            }
            else if (playerX.songRate > 70) {
                playerX.findAndPlayEffect("Regular");
            }
            else if (playerX.songRate <= 70) {
                playerX.findAndPlayEffect("Mal 3");
            }

            played = true;

            nextSongIn.value += 10;
        } else {
            nextSongIn.value += 10;
        }
    }, 500);
})
function getImage(image){
  currentImage.value = currentImage.value?currentImage.value:navigator.onLine?image:`/images/offline-default/${Math.floor(Math.random() * 3)}.webp`;
  return currentImage.value;
}
</script>

<style scoped lang="scss" src="./score.scss"></style>