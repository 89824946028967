import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { useUserStore } from "@/stores/user";
import { useUtilsStore } from "@/stores/utils";
import { trackRouter } from "vue-gtag-next";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const userX = useUserStore();
  const isLoggedIn = userX.isLoggedIn;
  const query = userX.isSubscribed ? null : { subscribed: 0 };

  //? if route is for users logged in only
  if (requiresAuth) {
    //? there is no user logged in
    if (!isLoggedIn) {
      //? return login with redirection
      const utilsX = useUtilsStore();

      utilsX.loginFirst();

      next({
        name: "login",
        query: {
          redirect: to.name,
          q: JSON.stringify(to.query),
          params: JSON.stringify(to.params),
        },
      });
      return;
    }
  } else {
    //? if route is public and there is a user logged in
    if (isLoggedIn && to.name !== 'finishOrder') {
      //? redirect to home view
      next({ name: "home", query });
      return;
    }
  }
  if (requiresAuth && query && !Object.prototype.hasOwnProperty.call(to.query, "subscribed")) {
    next({ ...to, query: { ...to.query, ...query } });
    return;
  }else if(requiresAuth && query === null && Object.prototype.hasOwnProperty.call(to.query, "subscribed")){
    delete to.query.subscribed;
    next({ ...to, query: { ...to.query } });
    return;
  }
  next();
});

trackRouter(router);

export default router;
