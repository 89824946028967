<template>
    <div class="eki-semaforo" :class="{
        hide: playerX.lightContent*1 === 0 || playerX.lightContent === null
    }">
        <div class="light red" :class="{
            'turned-on': playerX.lightContent*1 === 3
        }">3</div>
        <div class="light yellow" :class="{
            'turned-on': playerX.lightContent*1 === 2
        }">2</div>
        <div class="light green" :class="{
            'turned-on': playerX.lightContent*1 === 1
        }">1</div>
    </div>
</template>

<script setup>
import { usePlayerStore } from "@/stores/player";

const playerX = usePlayerStore();

</script>

<style scoped lang="scss" src="./lights.scss"></style>