<template>
  <div class="eki-notif" :class="[typeClass, positionClass, visibleClass]">
    <fa-icon
      v-if="utilsX.notif.type == 'info'"
      icon="fa-solid fa-circle-info"
      class="notif-icon"
    />
    <fa-icon
      v-if="utilsX.notif.type == 'warning'"
      icon="fa-solid fa-triangle-exclamation"
      class="notif-icon"
    />
    <fa-icon
      v-if="utilsX.notif.type == 'error'"
      icon="fa-solid fa-circle-xmark"
      class="notif-icon"
    />
    <fa-icon
      v-if="utilsX.notif.type == 'success'"
      icon="fa-solid fa-circle-check"
      class="notif-icon"
    />

    <div class="notif-info">
      <div class="title">{{ $t(utilsX.notif.title) }}</div>
      <div class="message">{{ $t(utilsX.notif.message) }}</div>
    </div>

    <fa-icon
      @click="closeNotif"
      icon="fa-solid fa-circle-xmark"
      class="notif-closer"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useUtilsStore } from "@/stores/utils";

const utilsX = useUtilsStore();

const typeClass = computed(() => utilsX.notif.type);
const positionClass = computed(() => utilsX.notif.position);
const visibleClass = computed(() =>
  utilsX.notif.isVisible ? "visible" : "invisible",
);

function closeNotif() {
  utilsX.closeNotif();
}
</script>

<style scoped lang="scss" src="./notifs.scss"></style>
