<template>
  <!-- z-index = 200 -->
  <EkiCookieBanner
    v-if="utilsX.showCookieBanner"
    @allowed="utilsX.runCookieDependencies()"
    @denied="utilsX.showCookieBanner = false"
  ></EkiCookieBanner>

  <!-- z-index = 100 -->
  <EkiScore
    v-if="utilsX.showScore && userX.settings.functionallity.showCalification"
  ></EkiScore>

  <!-- z-index = 90 -->
  <EkiTitle></EkiTitle>

  <!-- z-index = 80 -->
  <EkiLights></EkiLights>

  <!-- z-index = 70 -->
  <EkiNotifs></EkiNotifs>

  <!-- z-index = 60 DashboardTemplate | NotFoundTemplate -->
  <RouterView />
</template>

<script setup>
import EkiScore from "@/components/eki-score/EkiScore.vue";
import EkiTitle from "@/components/eki-title/EkiTitle.vue";
import EkiLights from "@/components/eki-lights/EkiLights.vue";
import EkiNotifs from "./components/eki-notifs/EkiNotifs.vue";
import EkiCookieBanner from "./components/eki-cookiebanner/EkiCookieBanner.vue";

import { useFirebaseStore } from "@/stores/firebase";
import { useUtilsStore } from "@/stores/utils";
import { useUserStore } from "@/stores/user";
import { RouterView } from "vue-router";
import { onMounted } from "vue";

const firebaseX = useFirebaseStore();
const utilsX = useUtilsStore();
const userX = useUserStore();

onMounted(() => {
  firebaseX.listenAuthChanges();
});
</script>

<style scoped></style>
